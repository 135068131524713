import React from 'react'

const Pillbutton = ({name}) => {
    return (
        <button className = "uppercase text-sm font-bold text-white px-5 py-3 bg-blue-500 rounded-full">{name}</button>

    )
}

export default Pillbutton
