import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/solid";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicFoot {
        nodes {
          data {
            company_fields {
              link
              name
            }
            dealers {
              link
              name
            }
            facebook_link {
              url
            }
            instagram_link {
              url
            }
            legal {
              link
              name
            }
            linkedin_link {
              url
            }
            product_fields {
              name
              link
            }
            twitter_link {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <footer className = "bg-gray-800 py-8 shadow">

          <div className="md:max-w-screen-md lg:max-w-screen-lg mx-auto flex-col flex space-y-2 divide-y divide-gray-600">
            {/*
            ======================
            NAVIGATION LINKS
            ======================
            */}
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 my-4 mx-6 md:mx-0">
              <ul className="flex flex-col space-y-2">
                <li className="text-sm font-semibold uppercase text-gray-300 md:text-base">
                  Products
                </li>
                {data.allPrismicFoot.nodes[0].data.product_fields.map((item) => {
                  return (
                    <Link to={item.link}>
                      <li className="text-gray-300 text-sm font-light hover:underline">
                        {item.name}
                      </li>
                    </Link>
                  );
                })}
              </ul>
              <ul className="flex flex-col space-y-2">
                <li className="text-sm font-semibold uppercase text-gray-300 md:text-base">
                  Company
                </li>
                {data.allPrismicFoot.nodes[0].data.company_fields.map((item) => {
                  return (
                    <Link to={item.link}>
                      <li className="text-gray-300 text-sm font-light hover:underline">
                        {item.name}
                      </li>
                    </Link>
                  );
                })}
              </ul>
              <ul className="flex flex-col space-y-2">
                <li className="text-sm font-semibold uppercase text-gray-300 md:text-base">
                  Dealers
                </li>
                {data.allPrismicFoot.nodes[0].data.dealers.map((item) => {
                  return (
                    <Link to={item.link}>
                      <li className="text-gray-300 text-sm font-light hover:underline">
                        {item.name}
                      </li>
                    </Link>
                  );
                })}
              </ul>
              <ul className="flex flex-col space-y-2">
                <li className="text-sm font-semibold uppercase text-gray-300 md:text-base">
                  Legal
                </li>
                {data.allPrismicFoot.nodes[0].data.legal.map((item) => {
                  return (
                    <Link to={item.link}>
                      <li className="text-gray-300 text-sm font-light hover:underline">
                        {item.name}
                      </li>
                    </Link>
                  );
                })}
              </ul>
            </div>
            {/*
            ======================
            SIGN UP FOR NEWSLETTER
            ======================
            */}
            <div className="flex flex-col md:flex-row py-6 space-y-4 md:space-y-0 md:space-x-2 items-center justify-between mx-6 md:mx-0">
              <div className="flex flex-col space-y-2 flex-grow">
                <h5 className="uppercase text-base text-center md:text-left font-bold text-gray-400">
                  Sign up for our Newsletter
                </h5>
                <p className="text-sm text-gray-300 font-light">
                  For all the Latest News about Electric
                </p>
              </div>
              <input
                className="focus:ring-blue-500 bg-gray-700 focus:border-blue-500 rounded-md sm:text-sm border-gray-600 px-3 py-3 form-input text-sm md:text-base placeholder-gray-500 text-gray-500 w-full md:w-64 focus:text-blue-500 shadow outline-none"
                type="email"
                placeholder="E-Mail Address"
              ></input>
              <span className = "group">
                  <div className = "p-3 bg-gray-600 rounded-md shadow group-hover:bg-blue-500 flex flex-row items-center">
                      <span className = "md:sr-only uppercase text-blue-500 group-hover:text-white group-hover:cursor-pointer text-sm font-semibold mr-4">Submit</span>
                      <ChevronRightIcon className = "h-6 w-6 text-blue-500 group-hover:text-white group-hover:cursor-pointer"/>
                  </div>
              </span>
            </div>
            <div className = "py-6 flex flex-col md:flex-row space-y-6 md-space-y-0 items-center mx-6 md:mx-0">
                <h3 className = "flex-grow text-sm text-center md:text-left text-gray-400"> Copyright &#169; 2021 Kabira Mobility Pvt. Ltd. All Rights Reserved</h3>
                <ul className = " flex flex-row space-x-2 items-center">
                    <li className = "text-sm text-gray-400 pr-3 sr-only md:not-sr-only">Follow Us On</li>
                    <li>
                      <a
                        href={data.allPrismicFoot.nodes[0].data.facebook_link.url}
                        target="_blank"
                      >
                        <svg
                          className="h-6 w-6 fill-current text-gray-400 hover:text-blue-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <title>Logo Facebook</title>
                          <path
                            d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>

                    <li>
                      <a
                        href={data.allPrismicFoot.nodes[0].data.instagram_link.url}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 fill-current text-gray-400 hover:text-blue-500"
                          viewBox="0 0 512 512"
                        >
                          <title>Logo Instagram</title>
                          <path d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z" />
                          <path d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href={data.allPrismicFoot.nodes[0].data.twitter_link.url}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 fill-current text-gray-400 hover:text-blue-500"
                          viewBox="0 0 512 512"
                        >
                          <title>Logo Twitter</title>
                          <path d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href={data.allPrismicFoot.nodes[0].data.linkedin_link.url}
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 fill-current text-gray-400 hover:text-blue-500"
                          viewBox="0 0 512 512"
                        >
                          <title>Logo Linkedin</title>
                          <path d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z" />
                        </svg>
                      </a>
                    </li>
                </ul>
            </div>
          </div>
      </footer>
    </>
  );
};

export default Footer;
