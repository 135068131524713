import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'

const BlankButton = ({text}) => {
    return (
        <button className="flex flex-row items-center">
            <p className = "text-base md:text-lg text-blue-700 font-light hover:font-medium hover:underline">{text}</p>
            <ChevronRightIcon className = "h-6 w-6 text-blue-700"/>
          </button>
    )
}

export default BlankButton